import { createContext, useState, useEffect, useCallback  } from "react";
export const authContext = createContext();

export default function AuthContextProvider({ children }) {
  const [isAuth, setAuth] = useState(false);
  const [Token, SetToken] = useState("");
  const [userName, setUserName] = useState(localStorage.getItem('username'));

  const [isEmpAuth, setEmpAuth] = useState(false);
  const [empToken, setEmpToken] = useState("");
  const [employeeName, setEmployeeName] = useState(localStorage.getItem('employeename'));
  const[isAdmin, setIsAdmin] = useState(JSON.parse(localStorage.getItem('isAdmin')));

  const [location, setLocation] = useState("");
  const [currentDayWeather, setCurrentDayWeather] = useState([]);
  //const [APIURL] = useState('//192.168.29.75:8000')
  //const [APIURL] = useState('//14.102.73.210:8000');
  const [APIURL] = useState('https://api.abraqnurseries.online');
  //const [APIURL] = useState('https://ab-n.onrender.com')


    const Login = (TkN) => {
      setAuth(true);
      SetToken(TkN);
    };
    const employeeLogin = (TkN) => {
      setEmpAuth(true);
      setEmpToken(TkN);
    }

    const LogOut = () => {
      setAuth(false);
      SetToken("");
    };

    const empployeeLogout = (TkN) => {
      setEmpAuth('false');
      setEmpToken("");
    }

  const getGeoLocation = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            const { latitude, longitude } = position.coords;
            resolve({ latitude, longitude });
          },
          function (error) {
            reject(error);
          }
        );
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  }, []);

  useEffect(() => {
    console.log('Effect is running');

    // for grower
    const storedUserName = localStorage.getItem('username');
    const storedUserToken = localStorage.getItem('token');
    if (storedUserName && storedUserToken) {
      setAuth(true);
      setUserName(storedUserName);
      SetToken(storedUserToken);
    }

    //for employee
    const storedEmployeeName = localStorage.getItem('employeename');
    const storedEmployeeToken = localStorage.getItem('employeetoken');
    if (storedEmployeeName && storedEmployeeToken) {
      setEmpAuth(true);
      setEmployeeName(storedEmployeeName);
      setEmpToken(storedEmployeeToken);
    }

  }, []);
  

  return (
    <authContext.Provider value={{ isAuth, setAuth, Token, SetToken, isEmpAuth, empToken, setEmpToken ,setEmpAuth ,Login, LogOut, employeeLogin, empployeeLogout ,userName, setUserName, employeeName, setEmployeeName,isAdmin ,setIsAdmin, location, setLocation, currentDayWeather, setCurrentDayWeather, APIURL, getGeoLocation }}>
      {children}
    </authContext.Provider>
  );
}